export function updateNavStyling () {
  const { $pinia } = useNuxtApp()
  const store = useStatesStore($pinia)
  const { transparentEffect } = storeToRefs(store)

  const nav = ref()
  const hasHero = ref()
  const hasFullSizeHero = ref(true)

  nav.value = document.querySelector('nav#desktop-menu-con')

  if (!nav.value) { return }

  if (!transparentEffect) {
    return (nav.value as HTMLElement).style.setProperty('position', 'sticky')
  }

  hasHero.value = document.querySelector('nav#desktop-menu-con + .page-wrapper > div')?.classList.contains('hero')
  if (!hasHero.value) {
    return (nav.value as HTMLElement).style.setProperty('position', 'sticky')
  }

  const navHeight = (nav.value as HTMLElement).offsetHeight
  const percentage = Math.round((window.scrollY / navHeight) * 100) / 100

  hasFullSizeHero.value = !!document.querySelector('nav#desktop-menu-con + .page-wrapper > .hero img:first-child') || !!document.querySelector('nav#desktop-menu-con + .page-wrapper > .hero video[data-fullscreen="true"]')
  if (hasFullSizeHero.value) {
    const opacity = percentage < 1
      ? percentage.toString()
      : '1';

    (nav.value as HTMLElement).style.setProperty('--tw-bg-opacity', opacity);
    (nav.value as HTMLElement).style.setProperty('--tw-border-opacity', opacity);
    (nav.value as HTMLElement).style.setProperty('position', 'fixed')
  } else {
    (nav.value as HTMLElement).style.setProperty('--tw-bg-opacity', '1');
    (nav.value as HTMLElement).style.setProperty('--tw-border-opacity', '1');
    (nav.value as HTMLElement).style.setProperty('position', 'sticky')
  }
}
